import { Box } from "@material-ui/core";
import FileViewer from "react-file-viewer";
import "../style.css";
const FilePreview = ({ filePath, fileType, imageUrl }) => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {imageUrl && (
        <div
          style={{
            height: "90%",
            width: "100%",
          }}
        >
          <FileViewer
            fileType={fileType} // Change this according to the file type
            filePath={filePath}
            onError={console.error}
          />
        </div>
      )}
    </Box>
  );
};

export default FilePreview;
