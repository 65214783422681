import moment from "moment-timezone";
export const convertServerTimeToLocaleTime = (time) => {
  if (moment.tz.guess(true) === "Africa/Lagos")
    return moment.tz(time, "Africa/Lagos").tz(moment.tz.guess(true));
  return moment.tz(time, "Africa/Lagos").utc().tz(moment.tz.guess(true));
};

export function conversationTimeFormat(timestamp) {
  const now = moment();
  const messageTime = convertServerTimeToLocaleTime(timestamp);

  // Immediate messages
  if (now.diff(messageTime, "seconds") <= 60) {
    return "Now";
  }
  // Last 60 minutes
  if (now.diff(messageTime, "minutes") <= 60) {
    return `${now.diff(messageTime, "minutes")} min`;
  }

  // Greater than 60 minutes but less than 24 hours
  if (now.diff(messageTime, "hours") < 24) {
    return messageTime.format("HH:mm");
  }

  // Previous day
  if (now.diff(messageTime, "days") === 1) {
    return `Yesterday ${messageTime.format("HH:mm")}`;
  }

  // Last 7 days
  // if (now.diff(messageTime, "days") <= 7) {
  return ` ${messageTime.format("ddd HH:mm")}`;
  // }

  // Days greater than 7 days but in the same year
  // if (
  //   now.diff(messageTime, "days") <= now.startOf("year").diff(moment(), "days")
  // ) {
  //   return messageTime.format("D MMM");
  // }

  // // Days in previous years
  // return messageTime.format("D MMM YYYY");
}
export function formatTime(timestamp) {
  const now = moment();
  const messageTime = convertServerTimeToLocaleTime(timestamp);

  // Immediate messages
  if (now.diff(messageTime, "seconds") <= 60) {
    return "Now";
  }

  // Last 60 minutes
  if (now.diff(messageTime, "minutes") <= 60) {
    return `${now.diff(messageTime, "minutes")} min`;
  }

  // Greater than 60 minutes but less than 24 hours
  if (now.diff(messageTime, "hours") < 24) {
    return messageTime.format("HH:mm");
  }

  // Previous day
  if (now.diff(messageTime, "days") === 1) {
    return `Yesterday ${messageTime.format("HH:mm")}`;
  }

  // Last 7 days
  // if (now.diff(messageTime, "days") <= 7) {
  return ` ${messageTime.format("ddd HH:mm")}`;
  // }

  // Days greater than 7 days but in the same year
  // if (
  //   now.diff(messageTime, "days") <= now.startOf("year").diff(moment(), "days")
  // ) {
  //   return messageTime.format("D MMM");
  // }

  // // Days in previous years
  // return messageTime.format("D MMM YYYY");
}
export function formatDate(timestamp) {
  const now = moment();
  const messageTime = convertServerTimeToLocaleTime(timestamp);

  // Messages from today
  if (now.isSame(messageTime, "day")) {
    return "Today";
  }

  // Messages from yesterday
  if (now.subtract(1, "days").isSame(messageTime, "day")) {
    return "Yesterday";
  }

  // Messages from last 7 days but not today or yesterday
  if (now.subtract(6, "days").isBefore(messageTime)) {
    return messageTime.format("dddd, D MMM");
  }

  // Messages from previous years
  return messageTime.format("dddd, D MMM YYYY");
}

export const groupMessagesByDate = (messages) => {
  const groupedMessages = [];
  let currentDate = null;
  let currentGroup = null;

  // Iterate through messages
  messages.forEach((message) => {
    const messageDate = new Date(message.created_at).getTime();

    // formatTime(message.created_at);
    // If message date is different from current date, create a new group
    if (messageDate !== currentDate) {
      // Add previous group to groupedMessages array
      if (currentGroup) {
        groupedMessages.push(currentGroup);
      }

      // Create a new group for the current date
      currentGroup = {
        date: messageDate,
        messages: [message],
      };

      // Update currentDate
      currentDate = messageDate;
    } else {
      // Add message to current group
      currentGroup.messages.push(message);
    }
  });

  // Add the last group
  if (currentGroup) {
    groupedMessages.push(currentGroup);
  }

  return groupedMessages;
};

export function shortenString(str, maxLength) {
  if (str) {
    if (str.length <= maxLength) {
      return str; // Return the original string if it's already shorter than maxLength
    } else {
      return str?.slice(0, maxLength) + "..."; // Shorten the string and add ellipsis
    }
  } else {
    return "";
  }
}

export function extractEmoji(input) {
  // Remove HTML tags
  const contentWithoutTags = input.replace(/<span[^>]*>|<\/span>/gi, "");
  // .replace(/<[^>]*>/g, "");

  // Replace instances of '\\\n' with '\n'
  const contentWithoutBackslashNewline = contentWithoutTags.replace(
    /\\\\n/g,
    "\\n"
  );

  return contentWithoutBackslashNewline.replaceAll("\\\n", "\n");
}

// export const convertServerTimeToLocaleTime = (serverTime, serverTimezone) => {
//   // Parse server time using moment
//   const serverMoment = moment.tz(serverTime, "Africa/Lagos");

//   // Guess the user's timezone
//   const userTimezone = moment.tz.guess();

//   // Convert server time to user's local time
//   const userLocalMoment = serverMoment.clone().tz(userTimezone);

//   // Format the local time
//   const formattedUserLocalTime = userLocalMoment.format("YYYY-MM-DD HH:mm:ss");

//   return formattedUserLocalTime;
// };
export const parseAndCorrectTime = (timeStr) => {
  const userTimezone = moment.tz.guess();
  // Parse the time string into a moment object
  let time = moment(timeStr, moment.ISO_8601, true).tz(userTimezone);

  // Check if the time is valid
  if (!time.isValid()) {
    // Manually correct the time string if it is invalid
    const parts = timeStr.split(":");
    if (parts.length === 3) {
      let seconds = parseFloat(parts[2]);
      if (seconds >= 60) {
        const validSeconds = seconds % 60;
        const minutesOverflow = Math.floor(seconds / 60);
        const minutes = parseInt(parts[1]) + minutesOverflow;
        parts[1] = minutes.toString().padStart(2, "0");
        parts[2] = validSeconds.toString().padStart(2, "0");
        timeStr = `${parts[0]}:${parts[1]}:${parts[2]}`;
      }
    }
    // Parse the corrected time string
    time = moment(timeStr, moment.ISO_8601, true).tz(userTimezone);
  }
  return time;
};

export function formatString(string, length) {
  if (!string) return "";
  if (length < 5) return string;
  if (string.length <= length) return string;
  return (
    string.slice(0, 10) +
    "..." +
    string.slice(string.length - length + 5, string.length)
  );
}
