import { Box, Typography, styled } from "@material-ui/core";
import Emoji, { gitHubEmojis } from "@tiptap-pro/extension-emoji";
import { Color } from "@tiptap/extension-color";
import Document from "@tiptap/extension-document";
import Link from "@tiptap/extension-link";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { AuthContext } from "api/AuthContext";
import { CustomText } from "common/Reusables";
import MarkdownIt from "markdown-it";
import React, { useContext } from "react";
import { socket } from "socket";
import {
  ArrowDown,
  DeleteIcon,
  DownloadIcon,
  EditIcon,
  ForwardIcon,
  Image,
  QuoteIcon,
  SenderVertIcon,
  Video,
} from "../Icons";
import { extractEmoji, formatString, formatTime, shortenString } from "../util";
import EditMessage from "./EditMessage";
import TaskUI from "./TaskUI";
import UserActionDialog from "./UserActionDialog";
import Download from "./Download";

const extensions = [
  Document,
  Paragraph.extend({
    addKeyboardShortcuts() {
      return {
        "Shift-Enter": () => this.editor.commands.keyboardShortcut("Enter"),
      };
    },
  }),
  Text,
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),

  ListItem.configure({
    HTMLAttributes: {
      class: "bullet-list",
    },
  }),
  OrderedList.configure({
    HTMLAttributes: {
      class: "ordered-list",
    },
  }),
  Paragraph.configure({
    HTMLAttributes: {
      class: "paragraph",
    },
  }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
  }),
  Underline,
  Emoji.configure({
    emojis: gitHubEmojis,
  }),
  Link.configure({
    openOnClick: false,
    autolink: true,
  }),
];

const HoverBox = styled(Box)({
  "&:hover": {
    backgroundColor: "#EAE4D9",
  },
});
const Message = ({
  message,
  messages,
  index,
  handleChatActions,
  handleOpen,
  downloadFile,
  setParentMessage,
  parentMessage,
  messageId,
  setMessageId,
  params,
}) => {
  const { user } = useContext(AuthContext);
  const [updatedMessage, setUpdatedMessage] = React.useState(message);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [text, setText] = React.useState(parentMessage?.message);
  const editorTipTap = useEditor({
    extensions,
    onUpdate: ({ editor }) => {
      if (editor.isEmpty) {
        setText(null);
      } else {
        const markdown = editorTipTap.getHTML();

        setText(markdown);
      }
    },
  });
  const [showArrow, setShowArrow] = React.useState(false);

  const showTime =
    formatTime(messages[index + 1]?.created_at) !==
    formatTime(updatedMessage?.created_at)
      ? true
      : false;

  const showImage =
    messages[index - 1]?.sender?.id !== updatedMessage?.sender?.id
      ? true
      : false;

  const domain = localStorage.getItem("business_id");
  const handleSendMessage = (e) => {
    e.preventDefault();

    socket.emit("edit-chat", {
      id: parentMessage.id,
      chat_room: parentMessage.room_id,
      message: extractEmoji(text),
      sender: user.id,
      receiver: parentMessage?.receiver?.id,
      is_edited: true,
      message_type: "text",
      parent: parentMessage?.parent?.id || null,
      business_id: domain,
    });
    setMessageId(null);
    setText("");
    editorTipTap.commands.clearContent();
  };

  const handleSendMessageToGroup = (e) => {
    e.preventDefault();
    socket.emit("edit-group-chat", {
      id: parentMessage.id,
      group: parentMessage?.group,
      message: extractEmoji(text),
      sender: user.id,
      message_type: "text",
      is_edited: true,
      parent: parentMessage?.parent?.id || null,
      business_id: domain,
    });
    setMessageId(null);
    setText(""); // Reset the message state
    editorTipTap.commands.clearContent();
  };
  const sendEditedMessage = (e) => {
    if (parentMessage.group) {
      handleSendMessageToGroup(e);
    } else {
      handleSendMessage(e);
    }
  };
  React.useEffect(() => {
    // const roomId = activeUser?.room_id;
    if (parentMessage) {
      socket.once(`edit-chat-${domain}-${parentMessage?.room_id}`, (data) => {
        setUpdatedMessage(data);
      });
    }
    return () => {
      socket.removeListener(`edit-chat-${domain}-${parentMessage?.room_id}`);
    };
  }, [text, domain, parentMessage]);

  React.useEffect(() => {
    socket.once(`edit-group-chat-${domain}-${parentMessage?.group}`, (data) => {
      setUpdatedMessage(data);
    });

    return () => {
      socket.removeListener(
        `edit-group-chat-${domain}-${parentMessage?.group}`
      );
    };
  }, [text, parentMessage, domain]);
  if (messageId === message?.id) {
    return (
      <EditMessage
        {...{
          text,
          setText,
          sendEditedMessage,
          parentMessage,
          setMessageId,
        }}
      />
    );
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
            marginBottom: "5px",
          }}
        >
          {updatedMessage.message_type === "shared_task" ? (
            <HoverBox
              sx={{
                width: "fit-content",
                maxWidth: "556px",
                borderRadius: "4px 0px 4px 4px",
                bgcolor: "#FEF6E6",
                padding: "10px",
                display: "flex",
                position: "relative",
                fontSize: "14px",
              }}
              onMouseEnter={() => {
                setShowArrow(true);
              }}
              onMouseLeave={() => {
                setShowArrow(false);
              }}
            >
              <Box
                // id="demo-positioned-button"
                // aria-controls={open ? "demo-positioned-menu" : undefined}
                // aria-haspopup="true"
                // aria-expanded={open ? "true" : undefined}
                onClick={() => {
                  setParentMessage(updatedMessage);
                  setShowArrow(true);
                  setAnchorEl(true);
                }}
                sx={{
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                {showArrow && <ArrowDown />}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "6px",
                }}
              >
                {updatedMessage?.is_edited && (
                  <CustomText
                    color="#A8A8A8"
                    fontSize="9px"
                    fontWeight="400"
                    fontFamily="Black Sans"
                  >
                    Edited
                  </CustomText>
                )}
                {updatedMessage?.forwarded && (
                  <CustomText
                    color="#A8A8A8"
                    fontSize="9px"
                    fontWeight="400"
                    fontFamily="Black Sans"
                  >
                    Forwarded
                  </CustomText>
                )}
                {updatedMessage?.parent?.message && (
                  <Box
                    sx={{
                      bgcolor: "#FDEDCE",
                      padding: "5px 8px",
                      borderRadius: "3px",
                      cursor: "pointer",
                    }}
                  >
                    {updatedMessage?.parent?.sender?.id !==
                      updatedMessage?.sender && (
                      <Typography
                        style={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          fontWeight: 600,
                          fontFamily: "Black Sans",
                          color: "#545454",
                        }}
                      >
                        {`${updatedMessage?.parent?.sender?.first_name} ${updatedMessage?.parent?.sender?.last_name}`}
                      </Typography>
                    )}
                    {updatedMessage?.parent?.message_type === "text" ? (
                      <Box
                        sx={{
                          position: "relative",
                          fontSize: "14px",
                          fontFamily: "Black Sans",
                          overflow: "hidden",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: updatedMessage?.parent?.message,
                        }}
                      ></Box>
                    ) : updatedMessage?.parent?.message_type ===
                      "shared_task" ? (
                      <TaskUI message={updatedMessage?.parent?.message} />
                    ) : (
                      <></>
                    )}
                  </Box>
                )}

                <TaskUI message={updatedMessage?.message} />
              </Box>
              {/* <PositionedMenu
                actions={[
                  {
                    label: "Quote reply",
                    icon: <QuoteIcon />,
                  },
                  {
                    label: "Edit message",
                    icon: <EditIcon />,
                  },
                  {
                    label: "Forward message",
                    icon: <ForwardIcon />,
                  },
                  {
                    label: "Delete message",
                    icon: <DeleteIcon />,
                  },

                  // {
                  //   label: "Add reaction",
                  //   icon: <EmojiIcon />,
                  // },
                  // { label: "Pin chat", icon: <PinIcon /> },
                ]}
                handleActions={(action) => {
                  setAnchorEl(null);
                  handleChatActions(action, updatedMessage);
                }}
                {...{
                  setAnchorEl,
                  anchorEl,
                  open,
                  updatedMessage,
                  parentMessage,
                }}
              /> */}

              {anchorEl && parentMessage?.id === updatedMessage?.id && (
                <UserActionDialog
                  handleActions={(action) => {
                    setAnchorEl(!anchorEl);
                    handleChatActions(action, updatedMessage);
                  }}
                  setOpen={setAnchorEl}
                  actions={
                    parentMessage?.message_type === "shared_task"
                      ? [
                          {
                            label: "Quote reply",
                            icon: <QuoteIcon />,
                          },
                          {
                            label: "Forward message",
                            icon: <ForwardIcon />,
                          },
                          {
                            label: "Delete message",
                            icon: <DeleteIcon />,
                          },
                          // {
                          //   label: "Add reaction",
                          //   icon: <EmojiIcon />,
                          // },
                          // { label: "Pin chat", icon: <PinIcon /> },
                        ]
                      : [
                          {
                            label: "Quote reply",
                            icon: <QuoteIcon />,
                          },
                          {
                            label: "Edit message",
                            icon: <EditIcon />,
                          },
                          {
                            label: "Forward message",
                            icon: <ForwardIcon />,
                          },
                          {
                            label: "Delete message",
                            icon: <DeleteIcon />,
                          },
                          // {
                          //   label: "Add reaction",
                          //   icon: <EmojiIcon />,
                          // },
                          // { label: "Pin chat", icon: <PinIcon /> },
                        ]
                  }
                  left="-85px"
                  top="40px"
                  right="50px"
                />
              )}
            </HoverBox>
          ) : updatedMessage.message_type === "text" ? (
            <HoverBox
              sx={{
                width: "fit-content",
                maxWidth: "556px",
                borderRadius: "4px 0px 4px 4px",
                bgcolor: "#FEF6E6",
                padding: "10px",
                display: "flex",
                position: "relative",
                fontSize: "14px",
              }}
              onMouseEnter={() => {
                setShowArrow(true);
              }}
              onMouseLeave={() => {
                setShowArrow(false);
              }}
            >
              <Box
                // id="demo-positioned-button"
                // aria-controls={open ? "demo-positioned-menu" : undefined}
                // aria-haspopup="true"
                // aria-expanded={open ? "true" : undefined}
                onClick={() => {
                  setParentMessage(updatedMessage);
                  setShowArrow(true);
                  setAnchorEl(true);
                }}
                sx={{
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                {showArrow && <ArrowDown />}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "6px",
                }}
              >
                {updatedMessage?.is_edited && (
                  <CustomText
                    color="#A8A8A8"
                    fontSize="9px"
                    fontWeight="400"
                    fontFamily="Black Sans"
                  >
                    Edited
                  </CustomText>
                )}
                {updatedMessage?.forwarded && (
                  <CustomText
                    color="#A8A8A8"
                    fontSize="9px"
                    fontWeight="400"
                    fontFamily="Black Sans"
                  >
                    Forwarded
                  </CustomText>
                )}
                {updatedMessage?.parent?.message && (
                  <Box
                    sx={{
                      bgcolor: "#FDEDCE",
                      padding: "5px 8px",
                      borderRadius: "3px",
                      cursor: "pointer",
                    }}
                  >
                    {updatedMessage?.parent?.sender?.id !==
                      updatedMessage?.sender && (
                      <Typography
                        style={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          fontWeight: 600,
                          fontFamily: "Black Sans",
                          color: "#545454",
                        }}
                      >
                        {`${updatedMessage?.parent?.sender?.first_name} ${updatedMessage?.parent?.sender?.last_name}`}
                      </Typography>
                    )}
                    {updatedMessage?.parent?.message_type === "text" ? (
                      <Box
                        sx={{
                          position: "relative",
                          fontSize: "14px",
                          fontFamily: "Black Sans",
                          overflow: "hidden",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: updatedMessage?.parent?.message,
                        }}
                      ></Box>
                    ) : updatedMessage?.parent?.message_type ===
                      "shared_task" ? (
                      <TaskUI message={updatedMessage?.parent?.message} />
                    ) : (
                      <></>
                    )}
                  </Box>
                )}
                <Box
                  sx={{
                    width: "fit-content",
                    maxWidth: "502px",
                    overflow: "hidden",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: updatedMessage?.message,
                  }}
                ></Box>
              </Box>

              {anchorEl && parentMessage?.id === updatedMessage?.id && (
                <UserActionDialog
                  handleActions={(action) => {
                    setAnchorEl(!anchorEl);
                    handleChatActions(action, updatedMessage);
                  }}
                  setOpen={setAnchorEl}
                  actions={[
                    {
                      label: "Quote reply",
                      icon: <QuoteIcon />,
                    },
                    {
                      label: "Edit message",
                      icon: <EditIcon />,
                    },
                    {
                      label: "Forward message",
                      icon: <ForwardIcon />,
                    },
                    {
                      label: "Delete message",
                      icon: <DeleteIcon />,
                    },
                    // {
                    //   label: "Add reaction",
                    //   icon: <EmojiIcon />,
                    // },
                    // { label: "Pin chat", icon: <PinIcon /> },
                  ]}
                  left="-85px"
                  top="40px"
                  right="50px"
                />
              )}
            </HoverBox>
          ) : (
            <Box
              sx={{
                position: "relative",
              }}
              onMouseEnter={() => {
                setShowArrow(true);
              }}
              onMouseLeave={() => {
                setShowArrow(false);
              }}
            >
              <Box
                // id="demo-positioned-button"
                // aria-controls={open ? "demo-positioned-menu" : undefined}
                // aria-haspopup="true"
                // aria-expanded={open ? "true" : undefined}
                onClick={() => {
                  setParentMessage(updatedMessage);
                  setShowArrow(true);
                  setAnchorEl(true);
                }}
                sx={{
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                {showArrow && <ArrowDown />}
              </Box>
              {["doc", "pdf"].includes(updatedMessage.message_type) ? (
                <Download
                  name={updatedMessage.message}
                  type={updatedMessage.message?.split(".")[1]}
                  handleDownload={handleOpen}
                  radius="6px 0px 6px 6px"
                  // bgcolor="#FEF6E6"
                />
              ) : (
                <Box
                  sx={{
                    width: "260px",
                    height: "93px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    bgcolor: "#f6f6f6",
                    gap: "6px",
                    cursor: "pointer",
                    padding: "12px 9px",
                    borderRadius: "0px 6px 6px 6px",
                  }}
                  onClick={handleOpen}
                >
                  {message.message_type === "image" ? (
                    <Image height="80" />
                  ) : (
                    <Video />
                  )}
                  <CustomText
                    color="#545454"
                    fontFamily="Black Sans"
                    fontSize="13px"
                    fontStyle="normal"
                    fontWeight="400"
                  >
                    {downloadFile.isFetching && params?.key === message?.file
                      ? "  Downloading..."
                      : formatString(message?.message, 20)}
                  </CustomText>
                </Box>
              )}
              {anchorEl && parentMessage?.id === updatedMessage?.id && (
                <UserActionDialog
                  handleActions={(action) => {
                    setAnchorEl(!anchorEl);
                    handleChatActions(action, updatedMessage);
                  }}
                  setOpen={setAnchorEl}
                  actions={[
                    {
                      label: "Delete message",
                      icon: <DeleteIcon />,
                    },
                  ]}
                  left="0"
                  top="0px"
                  right="50px"
                />
              )}
            </Box>
          )}

          {showTime && (
            <Box
              sx={{
                fontFamily: "Black Sans",
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400,
              }}
            >
              {updatedMessage?.is_edited
                ? formatTime(updatedMessage.updated_at)
                : formatTime(updatedMessage.created_at)}
            </Box>
          )}
        </Box>
        {updatedMessage.message_type === "text" && showImage && (
          <SenderVertIcon />
        )}
      </Box>
    </>
  );
};

export default Message;
