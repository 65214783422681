import { Box } from "@material-ui/core";
import { CustomText } from "common/Reusables";
import Button from "components/Button";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const TaskUI = ({ message }) => {
  const hisrory = useHistory();

  if (!message) {
    return <></>;
  }
  const parsedMessage = JSON.parse(message);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        padding: "20px",
        flexDirection: "column",
        alignItems: "start",
        gap: "8px",
        borderLeft: "2px solid #fc9f69",
      }}
    >
      <CustomText
        fontFamily="Black Sans"
        fontSize="16px"
        fontWeight={600}
        color="#171717"
      >
        {parsedMessage?.title}
      </CustomText>
      <CustomText
        fontFamily="Black Sans"
        fontSize="12px"
        fontWeight={400}
        color="#545454"
        dangerouslySetInnerHTML={{ __html: parsedMessage?.description }}
      >
        {/* {parsedMessage?.description} */}
      </CustomText>

      <Button
        type="task"
        label="View In Task App"
        onClick={() => hisrory.push(`tasks?taskId=${parsedMessage.id}`)}
      />
    </Box>
  );
};

export default TaskUI;
