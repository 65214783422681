import { Box } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import InfoIcon from "@mui/icons-material/Info";
import { Drawer, Stack } from "@mui/material";
import Tabs from "components/Tabs/MainTabs";
import qs from "query-string";
import { lazy, useContext, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { AuthContext } from "../api/AuthContext";
import { getUnreadCount } from "../api/notifications";
import Logo from "../assets/logos/MyEdge PNG.png";
import { NameIcon } from "../common/Reusables";
import RightSlide from "./Drawer";
import MoreNavbarModal from "./MoreNavbarModal";
import Notification from "./notifications/Notification";
import ProfileModal from "./ProfileModal";
import {
  DashboardIcon,
  DocGenIcon,
  MenuLink as Link,
  LinkTitle,
  MoreHorizIcon,
  PayrollIcon,
} from "./styled.navbar";
import Support from "./Support";

export const tabList = [
  {
    key: 1,
    name: "Home",
    view: "home",
    route: "/home",
  },
  {
    key: 2,
    name: "People",
    view: "people",
    route: "/people",
  },
  {
    key: 3,
    name: "Time-off",
    view: "timeoff",
    route: "/timeoffs",
  },
  {
    key: 4,
    name: "Training",
    view: "trainings",
    route: "/trainings",
  },
  {
    key: 5,
    name: "Benefits",
    view: "benefits",
    route: "/benefits",
  },
  {
    key: 6,
    name: "Documents",
    view: "documents",
    route: "/documents",
  },
  {
    key: 7,
    name: "Tasks",
    view: "tasks",
    route: "/tasks",
  },
  {
    key: 8,
    name: "Attendance",
    view: "attendance",
    route: "/attendance",
  },
  {
    key: 9,
    name: "Shift Rota",
    view: "shift",
    route: "/shift",
  },
  {
    key: 10,
    name: "Payslips",
    view: "payslips",
    route: "/payslips",
  },
  {
    key: 11,
    name: "Performance Review",
    view: "performance",
    route: "/performance",
  },
  {
    key: 12,
    name: "Settings",
    view: "settings",
    route: "/settings",
  },
];
const AuthNavBar = () => {
  const [openProfile, setOpenProfile] = useState(false);
  const [openMore, setOpenMore] = useState(false);
  const profileRef = useRef();
  const moreRef = useRef();
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const [openNotif, setOpenNotif] = useState(false);

  const { data: unreadCount } = useQuery("unread_count", getUnreadCount);

  const { tab } = qs.parse(window.location.search);

  const navItems = [
    {
      title: "Home",
      link: "/home",
      action: () => history.push("/home"),
      icon: ({ isactive }) => <DashboardIcon isactive={isactive} />,
    },
    {
      title: "People",
      link: "/people",
      action: () => history.push("/people"),
      icon: ({ isactive }) => <DocGenIcon isactive={isactive} />,
    },
    {
      title: "Payslips",
      link: "/payslips",
      action: () => history.push("/payslips"),
      icon: ({ isactive }) => <PayrollIcon isactive={isactive} />,
    },
    {
      title: "More",
      link: "/more",
      action: () => setOpenMore(!openMore),
      icon: ({ isactive }) => <MoreHorizIcon isactive={isactive} />,
    },
  ];
  const People = lazy(() => import("Pages/AuthPages/People"));
  const Timeoff = lazy(() => import("Pages/AuthPages/Timeoff"));
  const Performance = lazy(() =>
    import("Pages/AuthPages/Performance/routes.performance")
  );
  const Payslips = lazy(() => import("Pages/AuthPages/Payslips"));
  const Documents = lazy(() => import("Pages/AuthPages/Documents"));
  const Benefits = lazy(() => import("Pages/AuthPages/Benefits"));
  const Training = lazy(() => import("Pages/AuthPages/Training"));
  const Tasks = lazy(() => import("Pages/AuthPages/Tasks/routes.tasks"));
  const Attendance = lazy(() => import("Pages/AuthPages/Attendance"));
  const Settings = lazy(() => import("Pages/AuthPages/Performance/Settings"));
  const ShiftManagement = lazy(() => import("Pages/AuthPages/ShiftManagement"));

  const content = {
    people: People,
    timeoff: Timeoff,
    performance: Performance,
    trainings: Training,
    benefits: Benefits,
    documents: Documents,
    shift: ShiftManagement,
    attendance: Attendance,
    payslips: Payslips,
    tasks: Tasks,
    settings: Settings,
  };

  const Links = ({ width }) => {
    const { pathname } = useLocation();
    const _active = pathname.split("/").filter((i) => !!i)[0];

    return navItems.map((item) => {
      const Icon = item.icon;
      const isActive = item.link.includes(_active).toString();

      return (
        <Box key={item.title} isactive={isActive}>
          <Link isactive={isActive} to="#" onClick={item.action}>
            <Box textAlign="center" mb=".1rem">
              <Icon isactive={isActive} />
            </Box>
            <LinkTitle> {item.title}</LinkTitle>
          </Link>
        </Box>
      );
    });
  };

  const handleClickOutside = (e) => {
    console.log("clicking anywhere");
    if (moreRef.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpenMore(false);
  };

  useEffect(() => {
    if (openMore) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openMore]);

  const handleProfileOutside = (e) => {
    console.log("clicking anywhere");
    if (profileRef.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpenProfile(false);
  };

  useEffect(() => {
    if (openProfile) {
      document.addEventListener("mousedown", handleProfileOutside);
    } else {
      document.removeEventListener("mousedown", handleProfileOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleProfileOutside);
    };
  }, [openProfile]);
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  return (
    <Stack border={"1px solid #E1E1E1"} marginBottom={"32px"}>
      <Stack paddingX={"5rem"}>
        <AuthNavBar.Wrapper
          display="flex"
          justifyContent="space-between"
          position="relative"
        >
          <Box className="logo" onClick={() => history.push("/home")}>
            <img src={Logo} alt="bizedge logo" className="" />{" "}
            {/* <img src={user?.business_logo} alt={user?.business_name} className="" />{" "} */}
          </Box>

          {/* <Box
      className="links"
      display="flex"
      justifyContent="space-between"
      width="27%"
    >
      <Links />
    </Box> */}
          <Stack
            direction="row"
            spacing={"2rem"}
            className="notif"
            justifyContent="space-between"
            alignItems="center"
          >
            <InfoIcon
              htmlColor="#878787"
              style={{ cursor: "pointer" }}
              onClick={toggleDrawer(true)}
            />
            {/* <Badge badgeContent={unreadCount?.count} color="secondary">
              <NotificationsActiveIcon
                htmlColor="#878787"
                style={{ cursor: "pointer" }}
                onClick={() => setOpenNotif(true)}
              />
            </Badge> */}

            <Box
              className="img"
              onClick={() => setOpenProfile(true)}
              style={{ cursor: "pointer" }}
              display="flex"
              alignItems="center"
            >
              {user.photo ? (
                <img
                  src={user.photo}
                  alt="user"
                  style={{
                    verticalAlign: "midddle",
                    height: "32px",
                    width: "32px",
                    borderRadius: "50px",
                  }}
                />
              ) : (
                <NameIcon
                  scale="0.8"
                  first={user?.first_name}
                  second={user?.last_name}
                  margin="auto"
                />
              )}{" "}
              <KeyboardArrowDownIcon />
            </Box>
          </Stack>
          {openProfile && (
            <Box
              className="open-profile"
              position="fixed"
              minWidth="87%"
              left="70%"
              top="10%"
              style={{
                zIndex: "40",
              }}
              ref={profileRef}
            >
              <ProfileModal setOpenProfile={setOpenProfile} user={user} />
            </Box>
          )}

          {openMore && (
            <Box
              className="open-profile"
              position="absolute"
              minWidth="87%"
              top="120%"
              left="70%"
              ref={moreRef}
              style={{
                zIndex: "40",
              }}
            >
              <MoreNavbarModal setOpenMore={setOpenMore} />
            </Box>
          )}
          {/*  */}
          <RightSlide
            state={openNotif}
            setState={setOpenNotif}
            className="side-drawer2"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              className="header"
            >
              <div className="heading">Notification</div>
              <Box textAlign="end">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    // toggleOpenNotif();
                    setOpenNotif(false);
                  }}
                >
                  <CloseIcon style={{ cursor: "pointer" }} />
                </IconButton>
              </Box>
            </Box>
            <Notification closeModal={() => setOpenNotif(false)} />
            {/* {stages[stage]} */}
          </RightSlide>
          <Drawer anchor={"right"} open={open} onClose={toggleDrawer(false)}>
            <Support toggleDrawer={toggleDrawer} />
          </Drawer>
        </AuthNavBar.Wrapper>
      </Stack>

      <Stack paddingX={"5rem"}>
        <Tabs menus={tabList} isHome />
        {content[tab]}
      </Stack>
    </Stack>
  );
};

export default AuthNavBar;
AuthNavBar.Wrapper = styled(Box)`
  /* padding: 1rem 5rem; */
  padding: 1rem 0;
  border-bottom: 1px solid #f2f2f2;
  a {
    text-decoration: none;
    font-weight: normal;
    font-size: 14.22px;
    line-height: 15px;
  }
  .logo {
    width: 45%;
    cursor: pointer;
    img {
      vertical-align: middle;
      width: 120px;
    }
    span {
      font-weight: bold;
      font-size: 14.22px;
      line-height: 16px;
      color: #737373;
    }
  }
`;
